export enum TopificationVariant {
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory'
}

export type Props = {
  onSelect: (selectedIds: string[]) => void;
  selectedIds: string[];
  variant: TopificationVariant;
  productsByTopic?: any;
  maxItems?: number;
  searchText?: string;
  isMobile?: boolean;
};
