import classnames from 'classnames';

type Props = {
  className: string;
  label: string;
  marker?: string | number;
  icon?: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

function IconButton({ className, label, marker, icon, onClick }: Props) {
  return (
    <button
      type="button"
      className={classnames('button-iconed', className)}
      onClick={onClick}
    >
      <span className="button-iconed__label">{label}</span>
      <div className="button-iconed__icon-marker">
        {marker && marker > 0 && (
          <span className="button-iconed__marker">{marker}</span>
        )}
        <span className="button-iconed__icon">{icon}</span>
      </div>
    </button>
  );
}

export default IconButton;
