import { Component } from 'react';

const withLoading = BaseComponent => {
  return class EnhancedComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        show: false
      };
    }

    toggle = (forcedState = false) => {
      this.setState(prevState => ({
        show: forcedState || !prevState.show
      }));
    };

    open = () => {
      this.toggle(true);
    };

    close = () => {
      this.toggle(false);
    };

    render() {
      const { show } = this.state;

      const enhancedProps = {
        loading: {
          show,
          toggle: this.toggle,
          open: this.open,
          close: this.close
        }
      };

      return <BaseComponent {...enhancedProps} {...this.props} />;
    }
  };
};

export default withLoading;
