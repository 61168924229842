import React, { Component } from 'react';
import Cookie from 'js-cookie';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LazyHydrate from 'react-lazy-hydration';
import { withTranslation } from 'next-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { QUERY_IDS } from '@core/data/constants/query-ids';
import ContentLoader from '@core/presentation/components/content-loader';
import { ResultsTypes } from '@core/domain/constants/results';
import { v4 as uuid } from 'uuid';
import Service from '@core/data/service/service';
import config from '../../../env.config';
import { MarketplaceContext } from '../../components/core/Provider/MarketplaceProvider';
import SearchService from '../../@marketplace/service';

import Grid from '../../components/Grid';
import LoadMore from '../../components/LoadMore';
import IconButton from '../../components/Button/Icon';
import ProductCard from '../../components/Card/Product';
import EmptyStateResults from '../../components/EmptyState/Results';
import ProductsList from '../../components/Results/products/products-list';
import Loading, { withLoading } from '../../components/Loading';
import { TopificationVariant } from '../../components/Filter/Topification/types';
import { FILTER } from '../../constants';
import { sendAnalyticsEvent } from '../../utils/datalayer';
import * as DatahubService from '../../utils/datahub';
import { EVENT_ACTIONS, CLICK_SOURCES } from '../../utils/datahub.constants';

const Accordion = dynamic(() => import('components/Accordion'), { ssr: false });
const CreatorsList = dynamic(
  () => import('components/Results/creators/creators-list'),
  { ssr: false }
);
const FilterModal = dynamic(() => import('components/Modal/Filter'), {
  ssr: false
});

const FilterRow = dynamic(() => import('components/Filter/FilterRow'), {
  ssr: false
});

const RatingFilter = dynamic(() => import('components/Filter/Rating'), {
  ssr: false
});
const LanguageFilter = dynamic(() => import('components/Filter/Language'), {
  ssr: false
});
const OrderingFilter = dynamic(() => import('components/Filter/Ordering'), {
  ssr: false
});
const ProductCouponFilter = dynamic(() => import('components/Filter/Coupon'), {
  ssr: false
});
const ProductFormatFilter = dynamic(() => import('components/Filter/Format'), {
  ssr: false
});
const HorizontalFilter = dynamic(() => import('components/Filter/Horizontal'), {
  ssr: false
});
const ProductPaymentFilter = dynamic(
  () => import('components/Filter/Payment'),
  { ssr: false }
);
const TopificationFilter = dynamic(
  () => import('components/Filter/Topification'),
  { ssr: false }
);
const ProductCustomCouponFilter = dynamic(
  () => import('components/Filter/CustomCoupon'),
  { ssr: false }
);

const FilterProductsAuthor = dynamic(
  () => import('components/BlackNovember/FilterProductAuthor'),
  { ssr: false }
);

class ResultsContainer extends Component {
  constructor(props) {
    super(props);

    this.categoriesSelected = props.categoriesSelected || [];
    this.topificationsSelected = props.topificationsSelected || [];
    this.topicsSelected = props.topicsSelected || [];
    this.formatsSelected = props.formatsSelected || [];
    this.paymentSelected = props.paymentSelected || [];
    this.ratingsSelected = props.ratingsSelected || [];
    this.languagesSelected = props.languagesSelected || [];
    this.couponsSelected = props.couponsSelected || [];
    this.nichesSelected = props.nichesSelected || [];
    this.groupKeys = [];
    this.sortSelected = props.sortSelected || FILTER.ORDERING.MOST_RELEVANT;
    this.isFiltered = false;

    this.ratingInput = React.createRef();

    this.state = {
      showFilterModal: false,
      isMobile: props.isMobile,
      filterCount: [],
      openFilter: false,
      resultType: 'product',
      creatorProducts: [],
      creatorData: {}
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    const { seasonInfo } = this.props;

    if (seasonInfo?.GROUPS) {
      this.groupKeys = Object.keys(seasonInfo?.GROUPS)
        .map(item => seasonInfo?.GROUPS[item].key)
        .join();
    }

    this.getFilterCount();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getFilterCount = async () => {
    if (this.isFiltered) return;

    this.isFiltered = true;

    const isCampaign =
      this.props.seasonInfo && this.props.seasonInfo?.GROUP_KEY;
    let selectedGroupKeys = [];

    if (this.groupKeys) {
      selectedGroupKeys =
        this.nichesSelected?.length > 0
          ? this.nichesSelected.join()
          : this.groupKeys;
    }

    try {
      const data = await SearchService.getFiltersCount(
        {
          ...{
            searchText: this.props.searchText
          },
          size: 10,
          topifications: this.topificationsSelected,
          language: this.props.i18n.language,
          ...(this.props.isBlackNovemberPage
            ? { campaigns: this.props.seasonInfo?.COUPON_TYPE }
            : {}),
          ...(isCampaign ? { groupKeys: selectedGroupKeys } : {})
        },
        this.props.useQualityScoreQueries
      );

      this.isFiltered = false;
      this.setState({ filterCount: data });
    } catch (err) {
      console.error(err);
    }
  };

  handleResize = () => {
    const { isMobile } = this.state;
    const screenWidth = document.body.getBoundingClientRect().width;
    if (!isMobile && screenWidth < 840) {
      this.setState({
        isMobile: true
      });
    } else if (isMobile && screenWidth >= 840) {
      this.setState({
        isMobile: false
      });
    }
  };

  toggleFilterModal = () => {
    this.setState(
      state => ({
        showFilterModal: !state.showFilterModal
      }),
      () => {
        setTimeout(() => {
          this.ratingInput?.current?.focus();
        }, 100);
      }
    );
  };

  toggleResult = eventLabel => {
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: 'Marketplace:SearchResults',
      event_action: 'Click:EntityFilter',
      event_label: eventLabel
    });

    this.setState(state => ({
      resultType:
        state.resultType === ResultsTypes.PRODUCT
          ? ResultsTypes.CREATOR
          : ResultsTypes.PRODUCT
    }));
  };

  loadMoreClick = () => {
    const { onLoadMore } = this.props;

    if (typeof onLoadMore === 'function') {
      sendAnalyticsEvent({
        event: 'interaction',
        event_category: 'Marketplace:SearchResults',
        event_action: 'Click:LoadMore',
        event_label: 'Load More'
      });

      onLoadMore();
    }
  };

  applyFilter = (filterData = {}) => {
    const { onFilterApply, useQualityScoreQueries } = this.props;

    if (typeof onFilterApply === 'function') {
      this.formatsSelected = filterData.formats || [];
      this.paymentSelected = filterData.optionsPayment || [];
      this.categoriesSelected = filterData.categories || [];
      this.topificationsSelected = filterData.topifications || [];
      this.topicsSelected = filterData.topics || [];
      this.couponsSelected = filterData.coupons || [];
      this.nichesSelected = filterData.groupKeys || [];
      this.ratingsSelected = filterData.ratings || [];
      this.languagesSelected = filterData.languages || [];

      onFilterApply(filterData, useQualityScoreQueries);
    }
  };

  filterCategories = topics => {
    const subCategories = this.topicsSelected;

    if (this?.topificationsSelected?.length > 0) {
      subCategories?.map(category => {
        const isCategoryActivate = Object.keys(topics).includes(category);
        if (!isCategoryActivate) {
          this.topicsSelected = this.topicsSelected.filter(
            item => item !== category
          );
        }
        return isCategoryActivate;
      });
    } else {
      this.topicsSelected = [];
    }
  };

  clearSelection = () => {
    this.formatsSelected = [];
    this.paymentSelected = [];
    this.categoriesSelected = [];
    this.topificationsSelected = [];
    this.topicsSelected = [];
    this.couponsSelected = [];
    this.nichesSelected = [];
    this.ratingsSelected = [];
    this.languagesSelected = [];
    this.applyFilter();
  };

  onSelectFormat = formats => {
    const { isMobile } = this.state;

    this.formatsSelected = formats;

    // se nao for mobile, aplica os filtros automaticamente
    if (!isMobile) {
      this.applyFilter({
        formats: this.formatsSelected,
        optionsPayment: this.paymentSelected,
        categories: this.categoriesSelected,
        topifications: this.topificationsSelected,
        topics: this.topicsSelected,
        coupons: this.couponsSelected,
        groupKeys: this.nichesSelected,
        ratings: this.ratingsSelected,
        languages: this.languagesSelected
      });
    }
  };

  onSelectPayment = payment => {
    const { isMobile } = this.state;

    this.paymentSelected = payment;

    // se nao for mobile, aplica os filtros automaticamente
    if (!isMobile) {
      this.applyFilter({
        formats: this.formatsSelected,
        optionsPayment: this.paymentSelected,
        categories: this.categoriesSelected,
        topifications: this.topificationsSelected,
        topics: this.topicsSelected,
        coupons: this.couponsSelected,
        groupKeys: this.nichesSelected,
        ratings: this.ratingsSelected,
        languages: this.languagesSelected
      });
    }
  };

  onSelectRatings = ratings => {
    const { isMobile } = this.state;
    this.ratingsSelected = ratings || [];

    // se nao for mobile, aplica os filtros automaticamente
    if (!isMobile) {
      this.applyFilter({
        formats: this.formatsSelected,
        optionsPayment: this.paymentSelected,
        categories: this.categoriesSelected,
        topifications: this.topificationsSelected,
        topics: this.topicsSelected,
        coupons: this.couponsSelected,
        groupKeys: this.nichesSelected,
        ratings: this.ratingsSelected,
        languages: this.languagesSelected
      });
    }
  };

  onSelectLanguages = languages => {
    const { isMobile } = this.state;
    this.languagesSelected = languages;

    // se nao for mobile, aplica os filtros automaticamente
    if (!isMobile) {
      this.applyFilter({
        formats: this.formatsSelected,
        optionsPayment: this.paymentSelected,
        categories: this.categoriesSelected,
        topifications: this.topificationsSelected,
        topics: this.topicsSelected,
        coupons: this.couponsSelected,
        groupKeys: this.nichesSelected,
        ratings: this.ratingsSelected,
        languages: this.languagesSelected
      });
    }
  };

  onSelectTopification = async topifications => {
    const { isMobile } = this.state;
    const { searchText } = this.props;
    this.topificationsSelected = topifications;
    const subCategories = this.topicsSelected;

    try {
      const data = await SearchService.getFiltersCount(
        {
          ...{
            searchText
          },
          topifications: this.topificationsSelected,
          size: 10
        },
        this.props.useQualityScoreQueries
      );

      if (subCategories?.length > 0) {
        this.filterCategories(data?.TOPIC);
      }

      this.setState({ filterCount: data });
    } catch (e) {
      console.error(e);
    }

    // se nao for mobile, aplica os filtros automaticamente
    if (!isMobile) {
      this.applyFilter({
        formats: this.formatsSelected,
        optionsPayment: this.paymentSelected,
        categories: this.categoriesSelected,
        topifications: this.topificationsSelected,
        topics: this.topicsSelected,
        coupons: this.couponsSelected,
        groupKeys: this.nichesSelected,
        ratings: this.ratingsSelected,
        languages: this.languagesSelected
      });
    }
  };

  onSelectTopic = topics => {
    const { isMobile } = this.state;
    this.topicsSelected = topics;

    // se nao for mobile, aplica os filtros automaticamente
    if (!isMobile) {
      this.applyFilter({
        formats: this.formatsSelected,
        optionsPayment: this.paymentSelected,
        categories: this.categoriesSelected,
        topifications: this.topificationsSelected,
        topics: this.topicsSelected,
        coupons: this.couponsSelected,
        groupKeys: this.nichesSelected,
        ratings: this.ratingsSelected,
        languages: this.languagesSelected
      });
    }
  };

  onSelectCoupon = coupons => {
    this.couponsSelected = coupons;

    this.applyFilter({
      formats: this.formatsSelected,
      optionsPayment: this.paymentSelected,
      categories: this.categoriesSelected,
      topifications: this.topificationsSelected,
      topics: this.topicsSelected,
      coupons: this.couponsSelected,
      groupKeys: this.nichesSelected,
      ratings: this.ratingsSelected,
      languages: this.languagesSelected,
      filterByCoupon: true
    });
  };

  onSelectOrdering = (sort, forceApplyFilter) => {
    const { isMobile } = this.state;
    this.sortSelected = sort || '';

    if (!isMobile || forceApplyFilter) {
      this.applyFilter({
        formats: this.formatsSelected,
        optionsPayment: this.paymentSelected,
        categories: this.categoriesSelected,
        topifications: this.topificationsSelected,
        topics: this.topicsSelected,
        coupons: this.couponsSelected,
        sort: this.sortSelected || '',
        groupKeys: this.nichesSelected,
        ratings: this.ratingsSelected,
        languages: this.languagesSelected
      });
    }
  };

  getFilterCounter = () => {
    let filterCounter = 0;

    if (this.props?.allowNichesFilter) {
      filterCounter += this.props?.nichesSelected?.length || 0;
    }

    if (this.props?.allowCategoryFilter) {
      filterCounter += this.props?.categoriesSelected?.length || 0;
    }

    if (this.props?.allowTopificationFilter) {
      filterCounter += this.props?.topificationsSelected?.length || 0;
    }

    if (this.props?.allowTopicFilter) {
      filterCounter += this.props?.topicsSelected?.length || 0;
    }

    if (this.props?.allowFormatFilter) {
      filterCounter += this.props?.formatsSelected?.length || 0;
    }

    if (this.props?.allowPaymentFilter) {
      filterCounter += this.props?.paymentSelected?.length || 0;
    }

    if (this.props?.allowCouponFilter || this.props?.allowCustomCouponFilter) {
      filterCounter += this.props?.couponsSelected?.length || 0;
    }

    if (this.props?.allowRatingFilter) {
      filterCounter += this.props?.ratingsSelected?.length || 0;
    }

    if (this.props?.allowLanguageFilter) {
      filterCounter += this.props?.languagesSelected?.length || 0;
    }

    return filterCounter;
  };

  renderSearchBy = () => {
    const { searchText, t } = this.props;
    return (
      <div className="search-by">
        <div className="search-by__results-for">
          {t('results_page.title_results_for')}
          <strong>{`: ${searchText}`}</strong>
        </div>
      </div>
    );
  };

  renderFilterModal = () => {
    const {
      allowNichesFilter,
      allowCustomCouponFilter,
      allowPaymentFilter,
      categoriesSelected,
      formatsSelected,
      paymentSelected,
      ratingsSelected,
      languagesSelected,
      nichesSelected,
      couponsSelected,
      productsByCategory,
      seasonInfo,
      topicsSelected,
      topificationsSelected,
      searchText
    } = this.props;

    const { filterCount } = this.state;

    const filterCounter = this.getFilterCounter();

    return (
      <FilterModal
        nichesArray={seasonInfo?.GROUPS}
        niches={nichesSelected}
        categories={categoriesSelected}
        formats={formatsSelected}
        payments={paymentSelected}
        ratings={ratingsSelected}
        languages={languagesSelected}
        optionsPayment={paymentSelected}
        coupons={couponsSelected}
        filterCounter={filterCounter}
        filterCount={filterCount}
        applyFilter={this.applyFilter}
        onClose={this.toggleFilterModal}
        onClearSelection={this.clearSelection}
        allowNichesFilter={allowNichesFilter}
        allowPaymentFilter={allowPaymentFilter}
        allowCouponFilter={this.context.isBlackNovemberActive}
        allowCustomCouponFilter={allowCustomCouponFilter}
        productsByCategory={productsByCategory}
        ratingInput={this.ratingInput}
        productsByTopic={filterCount?.TOPIC}
        searchText={searchText}
        topics={topicsSelected}
        topifications={topificationsSelected}
      />
    );
  };

  renderMobileFilter = () => {
    const { t } = this.props;

    const filterCounter = this.getFilterCounter();

    return (
      <div className="info-header">
        <div className="info-header__filter">
          <IconButton
            label={t('season.filters.to_filter')}
            marker={`${filterCounter > 0 ? filterCounter : ''}`}
            icon={
              <FontAwesomeIcon
                icon={faFilter}
                className="_w-4 _h-4 _ml-2 _text-gray-900"
              />
            }
            onClick={this.toggleFilterModal}
          />
        </div>
      </div>
    );
  };

  renderFiltersSkeleton = isFilterLoading => {
    if (isFilterLoading) {
      return null;
    }

    const filtersSkeleton = [...Array(9)].map(() => {
      return (
        <ContentLoader
          className="_mb-4"
          width="100%"
          height={15}
          key={uuid()}
        />
      );
    });

    return filtersSkeleton;
  };

  renderSideFilter = () => {
    const {
      t,
      searchText,
      topificationsSelected,
      topicsSelected,
      couponsSelected,
      formatsSelected,
      paymentSelected,
      ratingsSelected,
      languagesSelected,
      allowTopificationFilter,
      allowTopicFilter,
      allowCouponFilter,
      allowCustomCouponFilter,
      allowFormatFilter,
      allowPaymentFilter,
      allowRatingFilter,
      allowLanguageFilter,
      isSeasonActive,
      i18n,
      seasonInfo
    } = this.props;

    const { filterCount } = this.state;
    let showCouponsSelected = couponsSelected;

    if (this.props.allSelectedCoupons) {
      showCouponsSelected = seasonInfo.COUPONS_VALUES;
    }

    const formatFilterTitle = label => (
      <span className="results-filter-placeholder__title__type">{label}</span>
    );

    return (
      <div className={classNames('results-filter-placeholder')}>
        {allowCouponFilter && (
          <ProductCustomCouponFilter
            onSelect={this.onSelectCoupon}
            selectedIds={couponsSelected}
            icon={`${config.envConfig.APP.URL}/s/static/images/black-november/filter-tag-black.png`}
            maxItems={0}
            showViewAllButton={false}
            productsByCoupon={filterCount?.COUPON_DISCOUNT}
            language={i18n?.language}
            t={t}
          />
        )}
        {allowRatingFilter && (
          <div className="results-filter-placeholder__title">
            <Accordion header={formatFilterTitle(t('season.filters.ratings'))}>
              <div className="_pt-2">
                <RatingFilter
                  onSelect={this.onSelectRatings}
                  selectedIds={ratingsSelected}
                  productsByRating={filterCount?.REVIEW}
                  campaign={seasonInfo}
                />
              </div>
            </Accordion>
          </div>
        )}
        {allowCustomCouponFilter && (
          <div className="results-filter-placeholder__title">
            <Accordion
              header={formatFilterTitle(t('season.filters.discounts'))}
              isActive
            >
              <div className="_pt-2">
                <ProductCouponFilter
                  onSelect={this.onSelectCoupon}
                  selectedIds={showCouponsSelected}
                  maxItems={0}
                  showViewAllButton={false}
                  productsByCoupon={filterCount?.COUPON_DISCOUNT}
                />
              </div>
            </Accordion>
          </div>
        )}
        {allowTopificationFilter && (
          <div className="results-filter-placeholder__title">
            <Accordion
              header={formatFilterTitle(t('season.filters.categories'))}
              isActive
            >
              <div className="_pt-2">
                {!filterCount?.TOPIFICATION && this.renderFiltersSkeleton()}
                {filterCount?.TOPIFICATION && (
                  <TopificationFilter
                    selectedIds={topificationsSelected}
                    maxItems={8}
                    onSelect={this.onSelectTopification}
                    variant={TopificationVariant.CATEGORY}
                    searchText={searchText}
                    productsByTopic={filterCount?.TOPIFICATION}
                  />
                )}
              </div>
            </Accordion>
          </div>
        )}
        {allowTopicFilter && (
          <div className="results-filter-placeholder__title">
            <Accordion
              header={formatFilterTitle(t('season.filters.subcategories'))}
              isActive
            >
              <div className="_pt-2">
                {!filterCount?.TOPIC && this.renderFiltersSkeleton()}
                {filterCount?.TOPIC && (
                  <TopificationFilter
                    selectedIds={topicsSelected}
                    maxItems={8}
                    onSelect={this.onSelectTopic}
                    variant={TopificationVariant.SUBCATEGORY}
                    productsByTopic={filterCount?.TOPIC}
                  />
                )}
              </div>
            </Accordion>
          </div>
        )}
        {allowCustomCouponFilter && isSeasonActive && (
          <div className="results-filter-placeholder__title">
            <Accordion
              header={formatFilterTitle(t('season.filters.discounts'))}
            >
              <div className="_pt-2">
                <ProductCouponFilter
                  onSelect={this.onSelectCoupon}
                  selectedIds={couponsSelected}
                  maxItems={0}
                  showViewAllButton={false}
                  productsByCoupon={filterCount?.COUPON_DISCOUNT}
                />
              </div>
            </Accordion>
          </div>
        )}
        {allowFormatFilter && (
          <div className="results-filter-placeholder__title">
            <Accordion header={formatFilterTitle(t('season.filters.formats'))}>
              <div className="_pt-2">
                <div className="results-filter-format">
                  <ProductFormatFilter
                    onSelect={this.onSelectFormat}
                    selectedIds={formatsSelected}
                    productsByFormat={filterCount?.FORMAT}
                  />
                </div>
              </div>
            </Accordion>
          </div>
        )}
        {allowPaymentFilter && (
          <div className="results-filter-placeholder__title">
            <Accordion header={formatFilterTitle(t('season.filters.payments'))}>
              <div className="_pt-2">
                <div className="results-filter-payment">
                  <ProductPaymentFilter
                    onSelect={this.onSelectPayment}
                    selectedIds={paymentSelected}
                    showViewAllButton
                    productsByFormat={filterCount?.OPTIONS_PAYMENT}
                  />
                </div>
              </div>
            </Accordion>
          </div>
        )}
        {allowLanguageFilter && (
          <div className="results-filter-placeholder__title">
            <Accordion header={formatFilterTitle(t('season.filters.language'))}>
              <div className="_pt-2">
                <div className="results-filter-language">
                  <LanguageFilter
                    onSelect={this.onSelectLanguages}
                    selectedIds={languagesSelected}
                    productsByLanguage={filterCount?.LANGUAGE}
                  />
                </div>
              </div>
            </Accordion>
          </div>
        )}
      </div>
    );
  };

  renderAltProductsList = (products, totalProducts) => {
    const {
      showSideFilter,
      showBuyButton,
      onProductClick,
      onProductBuyButtonClick,
      productIdPrefix,
      priceLoading,
      seasonInfo,
      origin,
      searchId
    } = this.props;
    const { isMobile } = this.state;
    const gridProps = { xs: 6, sm: 6, md: 6, lg: 4, xl: 3 };

    if (!showSideFilter) {
      gridProps.md = 4;
      gridProps.xs = 6;
      gridProps.lg = 3;
    }

    const sendDatahubEvent = (product, index) => {
      const productPosition = index;

      DatahubService.sendEvent({
        action: EVENT_ACTIONS.CLICK,
        sessionId: Cookie.get('sid'),
        productId: product?.productId,
        searchId,
        source: CLICK_SOURCES.RESULTS,
        origin,
        productPosition
      });
    };

    return (
      <>
        <Grid className="main-results">
          {products.map((product, index) => {
            return (
              <Grid.Item {...gridProps} key={product.productId}>
                <ProductCard
                  idPrefix={productIdPrefix}
                  product={product}
                  onClick={() => {
                    onProductClick(product);
                    sendDatahubEvent(product, index);
                  }}
                  onBuyButtonClick={() => onProductBuyButtonClick(product)}
                  showBuyButton={showBuyButton}
                  isMobile={isMobile}
                  showPrice={false}
                  price={product.price}
                  priceLoading={priceLoading}
                  seasonInfo={seasonInfo}
                  firstImagePriority={index === 0}
                />
              </Grid.Item>
            );
          })}
        </Grid>
        {totalProducts > config.envConfig.RESULTS.QTY_PER_PAGE && (
          <LoadMore
            onClick={this.loadMoreClick}
            seenProducts={products.length}
            totalProducts={totalProducts}
            hideTotal
          />
        )}
      </>
    );
  };

  getProductFromAuthor = async authorData => {
    const productsCreator = await Service.get(
      `${config.envConfig.CONTENT_ENDPOINT}/v1/product/search`,
      authorData
    );

    return productsCreator;
  };

  listProductFromAuthor = async owner => {
    try {
      this.setState(() => ({ creatorProducts: [], creatorData: owner }));

      if (owner?.id) {
        const listProductsCreator = await this.getProductFromAuthor({
          producerId: owner.id,
          queryId: QUERY_IDS.MAIN_QUERY,
          size: 8
        });

        if (listProductsCreator?.paging?.size) {
          this.setState(() => ({ creatorProducts: listProductsCreator }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  loadMoreProductsCreator = async () => {
    const listProductsCreator = this.state.creatorProducts;
    const totalProductsListCreator = listProductsCreator?.results?.length || 0;
    const lastProductListProductsCreator = totalProductsListCreator
      ? listProductsCreator?.results[totalProductsListCreator - 1]
      : null;
    let searchAfterScore;
    let searchAfterProductId;

    if (
      lastProductListProductsCreator &&
      lastProductListProductsCreator?.searchAfter
    ) {
      [
        searchAfterScore,
        searchAfterProductId
      ] = lastProductListProductsCreator.searchAfter;
    }

    const moreProductsCreator = await this.getProductFromAuthor({
      producerId: this.state.creatorData?.id,
      queryId: QUERY_IDS.MAIN_QUERY,
      size: 8,
      searchAfterScore,
      searchAfterProductId
    });

    const allProductsCreator = {
      paging: listProductsCreator?.paging,
      sort: listProductsCreator?.sort,
      results: [
        ...(listProductsCreator?.results?.length
          ? listProductsCreator.results
          : {}),
        ...(moreProductsCreator?.results?.length
          ? moreProductsCreator.results
          : {})
      ]
    };

    if (moreProductsCreator?.paging?.size) {
      this.setState(() => ({ creatorProducts: allProductsCreator }));
    }
  };

  setOrderingFilter = type => {
    const { showOrdenationFilter } = this.context;
    const { isMobile } = this.state;

    return (
      showOrdenationFilter && (
        <OrderingFilter
          type={type}
          isMobile={isMobile}
          onSelect={this.onSelectOrdering}
          setClosedFilter={this.setCloseFilter}
          sort={this.sortSelected}
        />
      )
    );
  };

  toggleCreator = () => {
    this.toggleResult('Creator');
    const { allSelectedCoupons, setAllSelectedCoupons } = this.props;

    if (allSelectedCoupons) {
      setAllSelectedCoupons(false);

      this.applyFilter({
        formats: this.formatsSelected,
        optionsPayment: this.paymentSelected,
        categories: this.categoriesSelected,
        topifications: this.topificationsSelected,
        topics: this.topicsSelected,
        coupons: this.couponsSelected,
        groupKeys: this.nichesSelected,
        ratings: this.ratingsSelected,
        languages: this.languagesSelected
      });
    }
  };

  renderDiscountOnTop = () => {
    const {
      showDiscountOnTop,
      couponsSelected,
      totalProducts,
      t,
      allSelectedCoupons,
      seasonInfo
    } = this.props;
    let showCouponsSelected = couponsSelected;

    if (allSelectedCoupons) {
      showCouponsSelected = seasonInfo.COUPONS_VALUES;
    }
    const { isMobile } = this.state;

    if (!showDiscountOnTop || totalProducts === 0) {
      return null;
    }

    return (
      <div>
        <FilterProductsAuthor
          toggleResult={this.toggleResult}
          toggleCreator={this.toggleCreator}
          isMobile={this.state.isMobile}
          resultType={this.state.resultType}
        />
        {showDiscountOnTop && this.state.resultType === ResultsTypes.PRODUCT && (
          <div className="results-section__header">
            <div className="results-section__header__discount-filter-top">
              <FilterRow
                label={
                  isMobile
                    ? `${t('season.filters.discounts_off')}`
                    : `${t('season.filters.filter_discounts')}`
                }
                selected={showCouponsSelected}
                onChange={selected => {
                  this.onSelectCoupon(selected);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderCreatorFilter = () => {
    const { t } = this.props;

    return (
      <div className="_d-flex _ml-md-3 _ml-1 _mb-4 _mb-md-0">
        <hot-button-group>
          <button
            type="button"
            className={classNames(
              'results-button-group hot-button hot-button--sm',
              {
                'results-button-group--active':
                  this.state.resultType === ResultsTypes.PRODUCT
              }
            )}
            onClick={() => this.toggleResult('Product')}
          >
            {t('general.products_plural')}
          </button>
          <button
            type="button"
            className={classNames(
              'results-button-group hot-button hot-button--sm',
              {
                'results-button-group--active':
                  this.state.resultType === ResultsTypes.CREATOR
              }
            )}
            onClick={() => this.toggleResult('Creator')}
          >
            {t('general.creators')}
          </button>
        </hot-button-group>
      </div>
    );
  };

  setOpenFilter = () => {
    this.setState(state => ({ openFilter: !state.openFilter }));
  };

  setCloseFilter = () => {
    this.setState({ openFilter: false });
  };

  setCategoriesFilter = type => {
    const { showOrdenationFilter } = this.context;
    const { formatsSelected, ratingsSelected, languagesSelected } = this.props;
    const { filterCount, openFilter } = this.state;

    return (
      showOrdenationFilter && (
        <HorizontalFilter
          type={type}
          onSelectFormat={this.onSelectFormat}
          onSelectRatings={this.onSelectRatings}
          onSelectLanguages={this.onSelectLanguages}
          formatsSelected={formatsSelected}
          ratingsSelected={ratingsSelected}
          opened={openFilter}
          setOpened={this.setOpenFilter}
          setClosed={this.setCloseFilter}
          languagesSelected={languagesSelected}
          filterCount={filterCount}
        />
      )
    );
  };

  render() {
    const {
      isLoading,
      products,
      totalProducts,
      couponsSelected,
      showSideFilter,
      searchText,
      showSearchBy,
      isSeasonActive,
      t,
      termNotFoundAnywhere,
      title,
      horizontalFilter = false,
      showList = true,
      locale,
      params
    } = this.props;

    const { showFilterModal, isMobile } = this.state;

    return (
      <>
        {isLoading && <Loading fixed />}

        {showFilterModal && isMobile && (
          <LazyHydrate whenVisible>{this.renderFilterModal()}</LazyHydrate>
        )}
        <div className={classNames('results-container', this.props.className)}>
          {title && (
            <div className="results-section__header">
              <h2 className="results-section__header__title">{title}</h2>
            </div>
          )}

          {!isMobile && searchText && showSearchBy && (
            <LazyHydrate whenVisible>{this.renderSearchBy()}</LazyHydrate>
          )}

          {isMobile && searchText && !this.props.isBlackNovemberPage && (
            <>
              {this.renderCreatorFilter()}
              <h1 className="results__search-term">
                <span>
                  {t('results_page.results_for', { count: products?.length })}
                </span>
                <span>{`${searchText}:`}</span>
              </h1>
            </>
          )}

          {this.props.isBlackNovemberPage &&
            isMobile &&
            this.renderDiscountOnTop()}

          {isMobile && (
            <>
              <div className="mobile-filters">
                <div className="mobile-filters__ordering">
                  {products?.length > 0 && this.setOrderingFilter('MOBILE')}
                </div>
                <div className="mobile-filters__filter">
                  {!termNotFoundAnywhere && this.renderMobileFilter()}
                </div>
              </div>
              {searchText && this.props.isBlackNovemberPage && (
                <div className="results__aux-bar__search results__aux-bar__search__black-november _ml-1 _mb-5">
                  {`${t('results_page.results_for', {
                    count: products?.length
                  })} `}
                  <span className="results__aux-bar__term">{`${searchText}:`}</span>
                </div>
              )}
            </>
          )}
          <div className="results-section">
            <div className="body-wrapper">
              <div className={classNames('content-placeholder')}>
                {this.props.isBlackNovemberPage &&
                  !isMobile &&
                  this.renderDiscountOnTop()}
                {products?.length > 0 && !isMobile && (
                  <div
                    className={classNames('results__aux-bar', {
                      'results__aux-bar--horizontal': horizontalFilter
                    })}
                  >
                    {horizontalFilter && (
                      <LazyHydrate whenVisible>
                        <div className="_d-sm-flex _align-items-sm-center">
                          {this.setCategoriesFilter('DESKTOP')}
                        </div>
                      </LazyHydrate>
                    )}
                    <LazyHydrate whenVisible>
                      <div
                        className={classNames(
                          '_d-sm-flex _align-items-sm-center results-filter-ordering'
                        )}
                      >
                        {this.setOrderingFilter('DESKTOP')}
                      </div>
                    </LazyHydrate>
                    {!this.props.isBlackNovemberPage &&
                      this.renderCreatorFilter()}
                    <div className="results__aux-bar__search _d-flex _align-items-center">
                      {this.props.searchText &&
                        `${t('results_page.results_for', {
                          count: this.props.products?.length
                        })}: `}
                      {this.props.searchText && (
                        <span className="results__aux-bar__term _ml-1">{`${this.props.searchText}`}</span>
                      )}
                    </div>
                    {isSeasonActive && (
                      <LazyHydrate whenVisible>
                        <ProductCustomCouponFilter
                          onSelect={this.onSelectCoupon}
                          selectedIds={couponsSelected}
                          maxItems={0}
                          theme="horizontal-tag"
                          showViewAllButton={false}
                        />
                      </LazyHydrate>
                    )}
                  </div>
                )}

                {this.state.resultType === ResultsTypes.PRODUCT &&
                  products &&
                  products.length > 0 &&
                  (showList ? (
                    <ProductsList
                      showSideFilter={this.props.showSideFilter}
                      showBuyButton={this.props.showBuyButton}
                      onProductClick={this.props.onProductClick}
                      onProductBuyButtonClick={
                        this.props.onProductBuyButtonClick
                      }
                      productIdPrefix={this.props.productIdPrefix}
                      priceLoading={this.props.priceLoading}
                      seasonInfo={this.props.seasonInfo}
                      origin={this.props.origin}
                      searchId={this.props.searchId}
                      isMobile={this.state.isMobile}
                      loadMoreClick={this.loadMoreClick}
                      totalProducts={totalProducts}
                      products={products}
                      locale={locale}
                      params={params}
                    />
                  ) : (
                    this.renderAltProductsList(products, totalProducts)
                  ))}

                {this.state.resultType === ResultsTypes.CREATOR &&
                  products &&
                  products.length > 0 && (
                    <CreatorsList
                      {...this.props}
                      isMobile={this.state.isMobile}
                      loadMoreClick={this.loadMoreProductsCreator}
                      getProductFromAuthor={this.listProductFromAuthor}
                      creatorData={this.state.creatorData}
                      creatorProducts={this.state.creatorProducts}
                    />
                  )}

                {products && products.length === 0 && !isLoading && (
                  <LazyHydrate whenVisible>
                    <EmptyStateResults
                      isMobile={this.state.isMobile}
                      termNotFoundAnywhere={this.props.termNotFoundAnywhere}
                      categories={this.props.categoriesSelected}
                      searchText={this.props.searchText}
                      isBlackNovemberPage={this.props?.isBlackNovemberPage}
                    />
                  </LazyHydrate>
                )}
              </div>

              {!termNotFoundAnywhere &&
                showSideFilter &&
                this.renderSideFilter()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
ResultsContainer.contextType = MarketplaceContext;

ResultsContainer.propTypes = {
  allowNichesFilter: PropTypes.bool,
  allowCategoryFilter: PropTypes.bool,
  allowFormatFilter: PropTypes.bool,
  allowPaymentFilter: PropTypes.bool,
  allowLanguageFilter: PropTypes.bool,
  allowRatingFilter: PropTypes.bool,
  allowCouponFilter: PropTypes.bool,
  allowTopificationFilter: PropTypes.bool,
  allowTopicFilter: PropTypes.bool,
  allowCustomCouponFilter: PropTypes.bool,
  categoriesSelected: PropTypes.arrayOf(PropTypes.string),
  formatsSelected: PropTypes.arrayOf(PropTypes.string),
  paymentSelected: PropTypes.arrayOf(PropTypes.string),
  optionsPayment: PropTypes.arrayOf(PropTypes.string),
  couponsSelected: PropTypes.arrayOf(PropTypes.string),
  nichesSelected: PropTypes.arrayOf(PropTypes.string),
  onFilterApply: PropTypes.func,
  onLoadMore: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  productsRelated: PropTypes.arrayOf(PropTypes.string),
  searchText: PropTypes.string,
  showDiscountOnTop: PropTypes.bool,
  showSearchBy: PropTypes.bool,
  showSideFilter: PropTypes.bool,
  title: PropTypes.string,
  totalProducts: PropTypes.number,
  showBuyButton: PropTypes.bool,
  onProductClick: PropTypes.func,
  onProductBuyButtonClick: PropTypes.func,
  productIdPrefix: PropTypes.string,
  initialSelectedCategory: PropTypes.string,
  searchId: PropTypes.string
};

ResultsContainer.defaultProps = {
  allowNichesFilter: false,
  allowCategoryFilter: true,
  allowFormatFilter: true,
  allowPaymentFilter: false,
  allowLanguageFilter: true,
  allowRatingFilter: true,
  allowTopificationFilter: true,
  allowTopicFilter: true,
  allowCouponFilter: false,
  allowCustomCouponFilter: false,
  categoriesSelected: [],
  formatsSelected: [],
  paymentSelected: [],
  couponsSelected: [],
  onFilterApply: () => {},
  onLoadMore: () => {},
  products: [],
  productsRelated: [],
  searchText: '',
  showDiscountOnTop: false,
  showBuyButton: false,
  showSearchBy: false,
  showSideFilter: true,
  title: '',
  totalProducts: 0,
  productIdPrefix: '',
  onProductClick: () => {},
  onProductBuyButtonClick: () => {},
  initialSelectedCategory: '',
  searchId: ''
};

export default withLoading(
  withTranslation('common')(React.memo(ResultsContainer))
);
