import React from 'react';
import classnames from 'classnames';
import Cookie from 'js-cookie';
import { publicIpv4 } from 'public-ip';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { getRemoteAddr } from 'utils/string';
import { loaderImage } from 'utils/image';
import { loadSearchMostWanted } from '../../../utils/search';
import { sendAnalyticsEvent } from '../../../utils/datalayer';
import SuggestionsList from '../../SuggestionsList';
import * as DatahubService from '../../../utils/datahub';

import {
  EVENT_ACTIONS,
  SEARCH_SOURCES
} from '../../../utils/datahub.constants';
import Suggestions from '../../Suggestions';

type EmptyStateResultsProps = {
  isMobile: boolean;
  categories: [];
  searchText: string;
  termNotFoundAnywhere: any;
  isBlackNovemberPage?: boolean;
};

function EmptyStateResults(props: EmptyStateResultsProps) {
  const { t, i18n } = useTranslation();
  let emptyStateProps = {
    emptyType: 'filter',
    littleImage: true,
    top: '',
    title: `${t('season.empty_state.filters_selected')}`,
    text: t('season.empty_state.text_with_filter')
  };
  let filterEmptyResults = {
    image: 'static/images/no-results-search.svg',
    size: 56
  };
  const classLittleImage = emptyStateProps.littleImage ? 'littleImage' : '';

  if (props?.isBlackNovemberPage) {
    filterEmptyResults = {
      image: 'static/images/no-results-search-bn.svg',
      size: 150
    };
  }

  if (props.termNotFoundAnywhere) {
    emptyStateProps = {
      emptyType: 'search',
      littleImage: false,
      top: t('season.empty_state.title'),
      title: props.searchText,
      text: t('season.empty_state.text_with_filter')
    };
  }

  const onSuggestionClick = async suggestion => {
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: 'Marketplace:Search:NoResults',
      event_action: 'Click:Suggestions',
      event_label: `${(suggestion && suggestion.key) || ''}`
    });

    DatahubService.sendEvent({
      action: EVENT_ACTIONS.SEARCH,
      locale: i18n?.language,
      term: `${(suggestion && suggestion.value) || ''}`,
      ip: getRemoteAddr(await publicIpv4()),
      sessionId: Cookie.get('sid'),
      source: SEARCH_SOURCES.NO_RESULTS,
      path: window.location.href
    });
  };

  const renderMostWanted = () => {
    const language = i18n?.language;

    if (['pt-BR', 'pt', 'pt-br'].indexOf(language) !== -1) {
      const searchData = loadSearchMostWanted(t, language, props.categories);

      return (
        <div className="no-results__most-wanted">
          <div className="no-results__most-wanted__title">
            {`${t('results_page.most_wanted')}:`}
          </div>
          <div>
            <SuggestionsList
              suggestions={searchData.suggestions}
              vertical
              light
              prefixUrl=""
              route="s"
              onSuggestionClick={onSuggestionClick}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={classnames(
        'no-results',
        `no-results--type-${emptyStateProps.emptyType}`,
        classLittleImage
      )}
    >
      <div className="no-results__container">
        <div className={classnames('no-results__icon', classLittleImage)}>
          <Image
            src={filterEmptyResults.image}
            loader={loaderImage}
            priority={false}
            width={filterEmptyResults.size}
            height={filterEmptyResults.size}
            alt=""
            role="presentation"
            className="no-results__icon__image"
          />
        </div>

        <div className="no-results__content">
          {emptyStateProps.top && (
            <span className="no-results__info">{emptyStateProps.top}</span>
          )}
          {emptyStateProps.title && (
            <span className="no-results__title">{emptyStateProps.title}</span>
          )}
          {emptyStateProps.text && (
            <span className="no-results__text">{emptyStateProps.text}</span>
          )}
        </div>
      </div>
      <Suggestions dataKey="viewedProducts" isMobile={props.isMobile} t={t} />
      {renderMostWanted()}
    </div>
  );
}

export default React.memo(EmptyStateResults);
