import { useEffect, useState } from 'react';
import Service from '@core/data/service/service';
import Slider from '../Slider';
import config from '../../../env.config';
import ProductCard from '../Card/Product';
import { sendAnalyticsEvent } from '../../utils/datalayer';
import { hasDataSaved } from '../../utils/localStorage';

function Suggestions({ dataKey, isMobile, t }) {
  const [productsSuggestions, setProductsSuggestions] = useState([]);

  const productOnClick = (product) => {
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: 'Marketplace:Search:NoResults',
      event_action: 'Click:Suggestions:ProductCard',
      event_label: `${(product && product?.title) || ''}`
    });
  };

  const getViewedProductsData = async viewedProducts => {
    try {
      const viewedProductsData = await Service.get(
        `${config.envConfig.CONTENT_ENDPOINT}/v1/product/search/ids`,
        { ids: viewedProducts.join(',') }
      );
      setProductsSuggestions(viewedProductsData);
    } catch (e) {
      console.error(e);
    }

  };

  useEffect(()=> {
    const viewedProducts = dataKey ? hasDataSaved(dataKey) : null;

    if (viewedProducts?.length >= 0) {
      getViewedProductsData(viewedProducts);
    }
  }, []);

  if (productsSuggestions?.length >= 4) {
    return (
      <div className="search__suggestions">
        <div className="search__section__title">
          <h2 className="no-results__most-wanted__title">
            {t('sections.suggestions')}
          </h2>
        </div>
        <Slider isMobile={isMobile} className="embla__container__wrapper">
          {productsSuggestions.map(product => {
            return (
              <div
                key={`suggestions-${product.productId}`}
                className="card-wrapper embla__slide__size_4"
              >
                <ProductCard
                  product={product}
                  onClick={() => productOnClick(product)}
                  isMobile={isMobile}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
  return null;

}

export default Suggestions;
